<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useMathGamesStore } from '~/store/mathGames'
import { useCurrentRoleStore } from '~/store/currentRole'

const currentRoleStore = useCurrentRoleStore()
const { currentRole } = storeToRefs(currentRoleStore)
const route = useRoute()

const drawerNavWithRoles = computed(() => {
  return [
    {
      id: 'dashboard',
      label: 'Dashboard',
      icon: 'i-heroicons-adjustments-horizontal',
      to: `/dashboard/${currentRole.value.role}/${currentRole.value.schoolId}`,
    },
    {
      id: 'games',
      label: 'Games',
      icon: 'i-heroicons-puzzle-piece',
      to: '/games',
    },
  ]
})

const drawerNavWithoutRoles = [
  {
    id: 'home',
    label: 'Home',
    icon: 'i-heroicons-home',
    to: '/',
  },
  {
    id: 'games',
    label: 'Games',
    icon: 'i-heroicons-puzzle-piece',
    to: '/games',
  },
]

const getNavigation = () => {
  if (currentRole.value.role) {
    return drawerNavWithRoles.value
  } else {
    return drawerNavWithoutRoles
  }
}
const drawerNavigation = ref(drawerNavWithoutRoles)

const gamesStore = useMathGamesStore()
const { resetGames } = gamesStore

const supabase = useSupabaseClient()
const isOpen = ref(false)
const signOut = async () => {
  const { error } = await supabase.auth.signOut()
  if (error) {
    useBugsnag().notify(error)
    console.log(error)
  }
  resetGames()
  isOpen.value = false
  navigateTo('/login')
}

const user = useSupabaseUser()
const schoolRoles = useGetUserSchools()

const { setCurrentRole } = useCurrentRoleStore()

const setUserRole = (schoolRole: SchoolRole) => {
  if (!schoolRole) return
  setCurrentRole(schoolRole)
}

onMounted(() => {
  if (schoolRoles.length === 1) {
    setUserRole(schoolRoles[0])
    return
  }
  drawerNavigation.value = getNavigation()
  if (
    user.value &&
    !currentRole.value.role &&
    route.path.includes('dashboard')
  ) {
    navigateTo(`/dashboard`)
  }
})
watch(
  () => currentRole.value,
  () => {
    drawerNavigation.value = getNavigation()
  },
)

const links = ref(useBreadcrumbItems())
const breadcrumbLinks = computed(() => {
  const isDashboard = links.value.some((link) => link.to === '/dashboard')
  if (isDashboard) {
    const dashboardUrl =
      currentRole.value.role && currentRole.value.schoolId
        ? `/dashboard/${currentRole.value.role}/${currentRole.value.schoolId}`
        : '/dashboard'
    return [
      {
        label: 'Home',
        to: '/',
      },
      {
        label: 'Dashboard',
        to: dashboardUrl,
      },
      ...(links.value.length > 4 ? [links.value[links.value.length - 1]] : []),
    ]
  }
  return links.value
})
</script>
<template>
  <div>
    <nav
      class="block grid grid-cols-2 md:grid-cols-3 shadow-md shadow-gray-200 w-full"
    >
      <NuxtLink to="/">
        <img src="/WLearn_Logo_Blue.svg" class="h-12 pt-2 pl-4" />
      </NuxtLink>
      <div class="hidden md:flex justify-center items-center">
        <span
          v-show="user?.user_metadata?.name"
          class="font-semibold text-lg text-primary text-center"
          >Welcome in<br />
          {{ currentRole?.schoolName }}</span
        >
      </div>
      <div class="flex flex-wrap items-center justify-end p-4">
        <span v-if="user?.email" class="no-underline hidden md:block">
          <NuxtLink to="/dashboard"> {{ user?.email }}</NuxtLink>
        </span>
        <NuxtLink v-show="!user?.email" to="/login" class="flex items-center">
          <UIcon name="i-heroicons-user-circle h-6 w-6 ml-4 cursor-pointer" />
        </NuxtLink>
        <UIcon
          name="i-heroicons-bars-3 h-6 w-6 ml-4 cursor-pointer"
          @click="isOpen = true"
        />
      </div>
    </nav>
    <UBreadcrumb
      v-show="breadcrumbLinks.length > 1"
      class="mt-6 ml-4"
      :links="breadcrumbLinks"
      :ui="{ ol: 'gap-x-3', li: 'gap-x-3 md:text-lg' }"
    >
      <template #divider>
        <span class="w-8 h-1 rounded-full bg-gray-300 dark:bg-gray-700" />
      </template>
    </UBreadcrumb>
    <slot />
    <USlideover v-model="isOpen">
      <UCard
        class="flex flex-col flex-1"
        :ui="{
          body: { base: 'flex-1' },
          ring: '',
          divide: 'divide-y divide-gray-100 dark:divide-gray-800',
        }"
      >
        <template #header>
          <ul class="text-blue-700">
            <li
              v-for="(item, index) in drawerNavigation"
              :key="`nav-item-${index}`"
              class="mt-4"
            >
              <NuxtLink
                class="flex flex-col justify-center h-12 py-8 hover:bg-blue-600 hover:text-white"
                :to="item.to"
                @click="isOpen = false"
              >
                <div class="flex">
                  <UIcon :name="item.icon" class="h-6 w-6 mx-4" />
                  <span class="font-bold text-lg">
                    {{ item.label }}
                  </span>
                </div>
              </NuxtLink>
            </li>
          </ul>
        </template>
        <template #footer>
          <NuxtLink
            v-show="user"
            class="flex flex-col justify-center h-12 py-8 hover:bg-blue-600 hover:text-white cursor-pointer rounded-lg"
            @click="signOut"
          >
            <div class="flex">
              <UIcon
                name="i-heroicons-arrow-right-on-rectangle"
                class="h-6 w-6 mx-4"
              />
              <span class="font-bold text-lg"> Sign out </span>
            </div>
          </NuxtLink>
        </template>
      </UCard>
    </USlideover>
  </div>
</template>
